import "./cta.scss"

import { Link } from "gatsby"
import React from "react"

export default (props) => {
  return (
    <section className="ob-cta padding-t-6 padding-b-6">
      <div className="row padding-t-6 padding-b-6">
        <div className="col-xs-12 text-center">
          <h2 className="text-white">{props.title}</h2>
          <Link to="/contact/" className="btn">
            {props.button}
          </Link>
        </div>
      </div>
    </section>
  )
}
