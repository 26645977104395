import "./serviceCard.scss"

import React from 'react';
import arrow from '../../../static/images/portfolio/Icon ionic-ios-arrow-round-forward.svg'

export default (props) => {
  return (
    <React.Fragment>
      <a className="more-service-card-link col-xs-12 col-sm-6" href={props.link}>
        <div className="more-services-card">
          <div className="row">
            <div className="col-md-4 basis-100 text-center pad-left">
              <img src={props.icon} alt="Section logo" className="service-icon"/>
            </div>
            <div className="col-md-8 basis-100 pad-left">
              <h2 className="serv-card-title">{props.title}<br></br>{props.title2}</h2>
              <div className="serv-card-button-div basis-100 margin-t-2 margin-b-2">
                <button className="serv-card-button margin-b-0">Learn More <img className="serv-card-button-icon" alt="service card button icon" src={arrow}/></button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </React.Fragment>
  )
}