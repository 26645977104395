import "./serviceLinks.scss"

import React from 'react';
import ServiceCard from "./serviceCard"
import Wave from "../wave"
import dataIcon from "../../../static/images/services/iconDataNoHex.svg"
import enterpriseIcon from "../../../static/images/services/iconEnterpriseNoHex.svg"
import mobileIcon from "../../../static/images/services/iconMobileNoHex.svg"
import prodEngIcon from "../../../static/images/services/iconProdEngNoHex.svg"
import sysIntIcon from "../../../static/images/services/iconSysIntNoHex.svg"
import webDevIcon from "../../../static/images/services/iconWebDevNoHex.svg"

export default (props) => {
  const serviceArray = props.serviceArray;
  const MAX_POS = serviceArray.length;
  const next = (props.pos+1<MAX_POS) ? props.pos+1 : 0;
  const following = (next+1<MAX_POS) ? next+1 : 0;
  

  return (
    <div className="service-links">
        <Wave color="#f8f8f8" background="#ffffff"/>
        <div className="col-xs-12 col-md-12 align-items-center">
          <h2 className="text-center">More Services</h2>
          <div className="row">
            <ServiceCard title={serviceArray[next].title} icon={process.env.filesBaseUrl+serviceArray[next].icon.publicUrl} link={serviceArray[next].href}/>
            <ServiceCard title={serviceArray[following].title} icon={process.env.filesBaseUrl+serviceArray[following].icon.publicUrl} link={serviceArray[following].href}/>
          </div>
        </div>
        
    </div>
  )
}